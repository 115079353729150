<template lang="pug">
div
  nav.navbar.sticky-top.brand-custom-navbar.brand-mobile-navbar
    .container-fluid.h-100
      router-link.navbar-brand(:to="{ name: dashboardRoute }" tag="div")
        img.logo(:src="customLogo || require('@/assets/om-logo.svg')")
      .cursor-pointer.navbar-nav-mobile-circle(v-if="campaignCount" @click="showMobileMenu = true")
        i.fa.fa-bars(aria-hidden="true")
      div(v-else)
        a.brand-link.ml-auto(href="javascript:void(0)" @click="logout") {{ $t('menu.signOut') }}
  transition(name="fade")
    .brand-mobile-menu(v-if="showMobileMenu")
      .d-flex.justify-content-end.brand-mobile-menu-close
        close-icon.cursor-pointer(:width="20" :height="20" @click.native="showMobileMenu = false")
      .brand-mobile-menu-account.d-flex.align-items-start
        om-avatar(
          :img="getAvatarUrl"
          :name="{ firstName: account.login.firstName, lastName: account.login.lastName }"
          big
        )
        .ml-3
          .font-weight-bold.font-size-0--9375 {{ getFullNameByLocale(account.login.firstName, account.login.lastName, account.locale) }}
          .font-size-0--875.brand-side-nav-profile-popper {{ account.login.email }}
      .brand-mobile-menu-profile-infos
        profile-infos
      .brand-mobile-menu-list
        router-link.om-mobile-menu-item(
          v-for="item in menuItems"
          :key="item.title"
          :to="{ name: item.route }"
          tag="div"
          @click.native="showMobileMenu = false"
          :class="{ 'brand-menu-item-beta': item.beta }"
        )
          span {{ item.title }}
        .d-flex.flex-column.brand-mobile-menu-profile-actions
          profile-actions(@close="showMobileMenu = false")
</template>
<script>
  import OmAvatar from '@/components/Avatar';
  import fullName from '@/mixins/fullName';
  import menuItems from '@/mixins/menuItems';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import ProfileInfos from '@/components/ProfileInfos';
  import ProfileActions from '@/components/ProfileActions';

  export default {
    components: { OmAvatar, ProfileInfos, ProfileActions },
    mixins: [menuItems, fullName],

    data() {
      return {
        showMobileMenu: false,
      };
    },

    computed: {
      ...mapState(['account']),
      ...mapGetters(['customLogo', 'getAvatarUrl']),
      campaignCount() {
        return this.account.campaignInfos.campaignCount;
      },
      menuItems() {
        return this.items.filter((item) => !item.hideOnMobile);
      },
    },

    methods: {
      ...mapActions(['logout']),
    },
  };
</script>

<style lang="sass">
  .brand-mobile-menu
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background: white
    z-index: 200002
    font-size: 17px
    padding: 0 0 16px
    overflow-y: auto
    svg path
      fill: white
    &-account
      color: white
      padding: 16px 52px 16px 24px
      background: var(--brand-primary-color)
    &-profile-infos
      padding:  20px 24px 24px
      border-bottom: 1px solid #E9EFF4
    &-close
      position: absolute
      top: 12px
      right: 0
      padding: 16px 24px
    div, div a
      font-size: 17px !important
    .brand-side-nav-profile-popper
      font-size: 14px !important
      box-shadow: none !important
    .brand-avatar-big
      height: 45px
      width: 45px
    .om-mobile-menu-item
      padding: 8px 24px
      cursor: pointer
      &:hover
        background: #F4F7FA
        color: var(--brand-primary-color)
        border-radius: 0
      &.brand-menu-item-beta
        span
          position: relative
          &:after
            display: inline-block
            content: "Beta"
            position: absolute
            top: -4px
            right: -24px
            font-size: 10px
    .brand-mobile-menu-profile-actions
      border-top: 1px solid #E9EFF4
    .brand-side-nav-profile-popper-item
      padding: 8px 24px
      max-width: 100%
</style>
